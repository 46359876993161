<template>
  <div class="opening">
    <div class="main">
      <h3 class="title">立即免费开通 专属活动店铺</h3>
      <div class="btn" @click="registerShow">免费开通</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Opening',
  props: {
    wwwUrl: {
      type: String,
      default: 'https://www.huodongge.cn'
    }
  },
  methods: {
    registerShow() {
      this.$store.dispatch('merchant/showRegister')
    }
  }
}
</script>

<style lang="scss" scoped>
.opening {
  width: 100%;
  height: 150px;
  background-image: url('https://static.huodongge.cn/www/images/u49.jpg');
  background-size: 100% 100%;
  .main {
    width: 700px;
    height: 150px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 32px;
      color: #fff;
    }
    .btn {
      color: #fff;
      font-size: 20px;
      width: 200px;
      height: 50px;
      display: inline-block;
      border: 1px solid #fff;
      line-height: 50px;
      border-radius: 2px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background: #fff;
        color: #409eff;
      }
    }
  }
}
</style>

<template>
  <!-- 介绍 -->
  <div class="introduce">
    <h3>配备自己的活动系统，升级传统营销策略</h3>
    <div class="introduce-img">
      <img
        src="https://img.huodongge.cn/uploads/image/20201202/ea662cfefff3ba25f4d48f12c591c893.png"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Introduce',
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.introduce {
  min-width: 1000px;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 80px;
  h3 {
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    color: rgba(85, 85, 85, 1);
  }
  .introduce-img {
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<template>
  <!-- 轮播图 -->
  <div class="carousel">
    <swiper
      class="main"
      v-if="swiperOptions"
      :options="swiperOptions"
      ref="mySwiper"
    >
      <swiper-slide v-for="(item, index) in homeSwiper" :key="index">
        <div
          class="main-item"
          :style="'background-image:url(' + item.cover_img + ')'"
        >
          <el-link
            class="btn-yingxiao"
            v-if="item.id == 2"
            :underline="false"
            @click="registerShow"
            >免费试用七天</el-link
          >
          <el-link
            class="btn-kaizhan"
            v-if="item.id == 3"
            :underline="false"
            @click="registerShow"
            >免费试用七天</el-link
          >
        </div>
      </swiper-slide>
    </swiper>
    <div class="control">
      <ul class="control-content">
        <li
          class="content-item"
          :class="{ active: activeIndex == index }"
          v-for="(item, index) in homeSwiper"
          :key="index"
          @mouseenter="changeSwiper(index)"
          @mouseleave="openTimer"
        >
          {{ item.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getCarousel } from '@/api/index'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
export default {
  name: 'Carousel',
  props: {
    wwwUrl: {
      type: String,
      default: 'https://www.huodongge.cn',
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      homeSwiper: [],
      // 轮播图配置
      swiperOptions: {
        loop: true,
        // 因为添加了循环,所以第一张图索引为1
        initialSlide: 1,
        on: {
          touchStart: () => {
            clearInterval(this.timer)
            this.touchState = true
          },
          touchEnd: () => {
            this.openTimer()
          },
          // 成功触发滑动至下一张
          slideNextTransitionStart: (swiper) => {
            if (this.touchState) {
              clearInterval(this.timer)
              this.touchState = false
              this.activeIndex = swiper.realIndex
              this.openTimer()
            }
          },
          // 成功触发滑动至上一张
          slidePrevTransitionStart: (swiper) => {
            if (this.touchState) {
              clearInterval(this.timer)
              this.touchState = false
              this.activeIndex = swiper.realIndex
              this.openTimer()
            }
          },
        },
      },
      // 判断是否为触摸,false为否
      touchState: false,
      // 当前选中的操纵栏
      activeIndex: 0,
      // 计时器
      timer: {},
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  created() {
    this.getCarousel()
  },
  mounted() {
    this.timer = setInterval(() => {
      this.swiper.slideNext(500, false)
      this.activeIndex = this.swiper.realIndex
    }, 3000)
    // 当页面关闭时自动销毁计时器
    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.timer)
    })
  },
  methods: {
    registerShow() {
      this.$store.dispatch('merchant/showRegister')
    },
    // 鼠标进入,切换轮播图和操作栏
    changeSwiper(index) {
      clearInterval(this.timer)
      this.activeIndex = index
      this.swiper.slideToLoop(index, 500, false)
    },
    // 离开打开计时器
    openTimer() {
      this.timer = setInterval(() => {
        this.swiper.slideNext(500, false)
        this.activeIndex = this.swiper.realIndex
      }, 3000)
    },
    async getCarousel() {
      // localStorage.removeItem('carouselList');
      let list = JSON.parse(localStorage.getItem('carouselList'))
      let newTime = Date.parse(new Date())
      let gap = false
      if (list != null) {
        gap = newTime - list[0].time >= 5 * 60 * 1000
      }
      if (list == null || gap) {
        const res = await getCarousel()
        if (res.status) return
        this.homeSwiper = res.data
        let time = Date.parse(new Date())
        res.data[0].time = time
        localStorage.removeItem('carouselList')
        localStorage.setItem('carouselList', JSON.stringify(res.data))
        // this.$http.get(getCarousel).then(res => {
        //   if (res.data.status) return;
        //   this.homeSwiper = res.data.data;
        //   let time = Date.parse(new Date());
        //   res.data.data[0].time = time;
        //   localStorage.removeItem("carouselList");
        //   localStorage.setItem("carouselList", JSON.stringify(res.data.data));
        // });
        return
      }
      this.homeSwiper = list
      // 赋值为了解决显示第一张图异常
      this.swiperOptions.initialSlide = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.carousel {
  width: 100%;
  max-width: 1920px;
  height: 500px;
  background: rgba(255, 255, 255, 0.15);
  margin: 0 auto;
  position: relative;
  .main {
    .main-item {
      width: 100%;
      height: 500px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      position: relative;
      .btn-yingxiao {
        width: 144px;
        height: 42px;
        position: absolute;
        top: 243px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: -480px;
        font-size: 18px;
        color: #ffffff;
        background: rgba(64, 158, 255, 1);
        padding: 0;
        text-align: center;
        line-height: 42px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
      }
      .btn-kaizhan {
        position: absolute;
        top: 242px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: -270px;
        width: 144px;
        height: 42px;
        font-size: 18px;
        color: rgba(0, 0, 0, 1);
        background: rgba(236, 159, 81, 1);
        padding: 0;
        text-align: center;
        line-height: 42px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
      }
    }
  }
  .control {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.15);
    z-index: 2;
    .control-content {
      max-width: 1120px;
      margin: 0 auto;
      .content-item {
        width: 25%;
        height: 60px;
        display: inline-block;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 0;
        background: rgba(0, 0, 0, 0.3);
        color: rgba(255, 255, 255, 0.5);
        font-size: 16px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
        &:last-child {
          border-right: none;
        }
      }
      .active {
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
      }
    }
  }
}
</style>
